<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


import AgentAutoComplete from '@/components/agent-auto-complete-input'
import {getBrokerageApi} from '@/api/brokerage'

import {
  required,

} from "vuelidate/lib/validators";


/**
 * New Staff component
 */
export default {
  page: {
    title: "Team",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  props: {

  },

  data() {
    return {
      title: "Brokerage",
      items: [
        {
          text: "Team",
          href: "/brokerage/team/grid",
        },
        {
          text: "New",
          active: true,
        },
      ],
      edit: false,
      leader_name: '',
      member_name : '',
      team_id     : '',
      award       : 100,
      leader: {},
      member_list: [],
      current_agent : {}
    };
  },


  components: {
    Layout,
    PageHeader,
    AgentAutoComplete
  },

  validations() {
   
    return {
      leader_name: { required }
    }
    
  },

  methods: {

    formSubmit() {
      this.$v.$touch();
      if (this.$v.$error == false) {

        let data = {
          team_leader  : this.leader,
          team_members : this.member_list
        }
        if (this.edit == true) {
          data.team_id = this.team_id
          getBrokerageApi().officeApi.team_edit(data).then((res) => {
            if (res.errCode == 0) {
              this.$router.push({ path: '/brokerage/team/grid' })
            } else {
              this.$alertify.error("Operation  Failed "+ res.errCode);
            }
          })
        } else {
          getBrokerageApi().officeApi.team_create(data).then((res) => {
            if (res.errCode == 0) {
              this.$router.push({ path: '/brokerage/team/grid' })
            } else {
              this.$alertify.error("Operation  Failed "+ res.errCode);
            }
          })
        }
      }
    },

    onSelectedAgent(evt) {
      if (evt.user_obj.is_leader == true) {
        this.leader          = evt.agent
        this.leader.agent_id = evt.agent.id
        this.leader_name =  evt.agent.first_name +' ' +evt.agent.last_name
      } else {
       
        this.current_agent = evt.agent
       
      }
    },


    onRemoveMember(data) {
      this.member_list.splice(data.index, 1)
    },

    add_team_member() {
      if (this.leader.code ==this.current_agent.code) {
        this.$alertify.error("Team Member is Leader");
          return 
        }

      let cache = this.member_list.find(e => e.code == this.current_agent.code)
        if (!cache) {
          this.member_list.push({
            agent_id : this.current_agent.id,
            name     : this.current_agent.first_name +' ' +this.current_agent.last_name,
            code     : this.current_agent.code,
            award    : this.award
          })
        }
    }




  },

  beforeUpdated() {

  },

  created() {
    
  },

  mounted() {
   
    if (this.$route.query.team_id) {
      getBrokerageApi().officeApi.team_info({team_id : this.$route.query.team_id}).then((res) => {
        if (res.errCode == 0) {
           res.data.agents.map(a => {
            if (a.team_flag == 2) {
             
              this.leader = {
                agent_id : a.id,
                name     : a.first_name +' ' +a.last_name,
                code     : a.code,
              }
              this.leader_name =  a.first_name +' ' +a.last_name
              this.$refs['leader_ref'].update_input_agent_name(this.leader_name)
            } else {
              this.member_list.push({
                agent_id : a.id,
                name     : a.first_name +' ' +a.last_name,
                code     : a.code,
                award    : a.team_award
              })
            }
           })
          this.edit = true
          this.team_id = this.$route.query.team_id
        } else {
          this.$alertify.error("Operation  Failed "+ res.errCode);
        }
      })
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-4">New Team</h5>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        01
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">General Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Team Leader</label>
                          <AgentAutoComplete ref="leader_ref" v-model="leader_name" @onSelected="onSelectedAgent" autocomplete="off"
                            :init_data="{ style_class: { 'is-invalid': $v.leader_name.$error }, agent_name: leader_name, user_obj: { is_leader: true } }" />
                          <div v-if="$v.leader_name.$error" class="invalid-feedback">
                            <span v-if="!$v.leader_name.required">This value is required.</span>
                          </div>

                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
              <!-- end card -->



              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        02
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Team Members</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Team Member</label>
                          <AgentAutoComplete v-model="member_name" @onSelected="onSelectedAgent" autocomplete="off"
                            :init_data="{ user_obj: { is_leader: false } }" />
                         
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Award Portion(%)</label>
                          <input class="form-control" v-model="award" placeholder="Award Portion" />
                         
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3 mt-3">
                          <b-button variant="primary" class="form-control" @click="add_team_member">Add Member</b-button>
                         
                        </div>
                      </div>

                    </div>


                  <div class="row">
                    <div class="table-responsive">
                        <div class="table align-middle table-nowrap">
                            <b-table :items="member_list" :fields="[{ key: 'Name' }, { key: 'Award' }, {key : 'Action'}]"
                                responsive="sm" :per-page="member_list.length" class="table-check">

                                <template #cell(Name)="data">
                                    {{ data.item.name +'('+data.item.code+')' }}
                                </template>
                                <template #cell(Award)="data">
                                    {{ data.item.award +'%' }}
                                </template>
                              
                                <template #cell(Action)="data">
                                    <b-button variant="outline-danger" size="sm" @click="onRemoveMember(data)">
                                    <i class="mdi mdi-close"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                    </div>
                  </div>

                </div>
              </div>
              <!-- end card -->

              <div class="text-end">
                <button type="submit" class="btn btn-primary w-sm">
                  Submit
                </button>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>
